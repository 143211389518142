// src/App.js
import React from 'react';

function App() {
  return (
    <div className="App">
      <h1>Chatbot Demo</h1>
      <p>Ez az oldal tartalmazza a chatbot iframe-eket.</p>
    </div>
  );
}

export default App;