// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ChatbotIcon from './ChatbotIcon';
import ChatWindow from './ChatWindow';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Chatbot icon iframe
if (window.location.pathname === '/chatbot-icon') {
  const iconRoot = ReactDOM.createRoot(document.getElementById('root'));
  iconRoot.render(
    <React.StrictMode>
      <ChatbotIcon />
    </React.StrictMode>
  );
}

// Chatbot window iframe
if (window.location.pathname === '/chatbot-window') {
  const windowRoot = ReactDOM.createRoot(document.getElementById('root'));
  windowRoot.render(
    <React.StrictMode>
      <ChatWindow />
    </React.StrictMode>
  );
}