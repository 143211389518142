// src/ChatWindow.js
import React, { useState } from 'react';

function ChatWindow() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setInput('');
      setTimeout(() => {
        setMessages(prev => [...prev, { text: 'Ez egy demo válasz.', sender: 'bot' }]);
      }, 1000);
    }
  };

  return (
    <div className="w-full h-full bg-white flex flex-col">
      <div className="bg-blue-500 text-white p-4">
        <h3 className="font-bold">Chatbot Demo</h3>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((msg, index) => (
          <div key={index} className={`mb-2 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded-lg ${msg.sender === 'user' ? 'bg-blue-100' : 'bg-gray-200'}`}>
              {msg.text}
            </span>
          </div>
        ))}
      </div>
      <div className="p-4 border-t">
        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-1 border rounded-l-lg p-2"
            placeholder="Írj egy üzenetet..."
          />
          <button
            onClick={handleSend}
            className="bg-blue-500 text-white p-2 rounded-r-lg"
          >
            Küldés
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatWindow;